import { useState, useEffect } from 'react' 
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'
import Link from 'next/link'
import dynamic from 'next/dynamic' 
import logo from '../public/img/black.png'
import menu from '../public/img/menu.png'
import search from '../public/img/search.png'
import close from '../public/img/close.png' 

const Search = dynamic(() =>
  import('./search').then((mod) => mod.Search)
)

const Sidebar = dynamic(() =>
  import('./sidebar').then((mod) => mod.Sidebar)
)

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

export function Header() {

    const [showResults, setShowResults] = useState(false)
    const [data, setData] = useState([]) 
    const [big, setBig] = useState([]) 

    const dataEndpoint = () => `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/carousels`

    // let timeout
    // let scroll = 0
    
    useEffect(() => { 
        fetch(dataEndpoint())
            .then(res => res.json())
            .then(res => {
                setData(res.data)                      
                // console.log('z', res.data)
            })  
    }, []); 
    const toggleClick = () => setShowResults(wasOpened => !wasOpened) 
    // h-[550px] sm:h-[300px] md:h-[410px] lg:h-[540px] xl:h-[770px] 2xl:h-[1350px] bg-mobile sm:bg-header md:bg-header lg:bg-header bg-contain bg-top bg-no-repeat

    return ( 
        <div className="w-full"> 
            {/* <div className="relative h-[550px] sm:h-[300px] md:h-[410px] lg:h-[540px] xl:h-[810px] 2xl:h-[1080px] 4xl:h-[1180px]">
                <div className="absolute top-0 left-0 right-0 hidden sm:block md:block lg:block xl:block 2xl:block 4xl:block"> */}
            <div className="relative">
                <div className="w-full hidden sm:block md:block lg:block xl:block 2xl:block 4xl:block">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        pagination={{ clickable: true }} 
                        className="headBul"
                        >
                        {   
                        data?.length > 0 
                            ?   data.map((data, key) => (
                                    <SwiperSlide key={key}>
                                        <img src={data.carousel_large} alt="header" className="w-full"/>
                                        {/* <div className="headDes">
                                            <h1 className="text-xl sm:text-xl lg:text-4xl prata text-white font-normal mb-2 s-text">{data.title}</h1>
                                            <p className="text-xs sm:text-sm lg:text-base sora text-white font-light mb-8 s-text">{data.description}</p>
                                        </div> */}
                                    </SwiperSlide>  
                                )) 
                            :   <SwiperSlide>
                                    <img src={"img/default_carousel.jpg"} alt="Default Carousel" className="w-full"/>
                                </SwiperSlide>  
                        } 
                    </Swiper> 
                </div>
                {/* <div className="absolute top-0 left-0 right-0 block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 4xl:hidden "> */}
                <div className="w-full block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 4xl:hidden ">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        pagination={{ clickable: true }} 
                        className="headBul"
                        >
                        { data.map((data, key) => (
                            <SwiperSlide key={key}>
                                <img src={data.carousel_small} alt="header" className="w-full"/>
                                {/* <div className="headDes">
                                    <h1 className="text-xl sm:text-xl lg:text-4xl prata text-white font-normal mb-2 s-text">{data.title}</h1>
                                    <p className="text-xs sm:text-sm lg:text-base sora text-white font-light mb-8 s-text">{data.title}</p>
                                </div> */}
                            </SwiperSlide>   
                            )) 
                        } 
                    </Swiper> 
                </div> 
                { showResults ? <Sidebar /> : null } 
            </div>
        </div>
    ) 
}